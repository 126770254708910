import { APIResponse, PageableParams, PageableResponse } from "./interfaces";
import {
  BankAccount,
  Company,
  CompanyAddress,
  CompanyConfirmStatus,
  CompanyCreateAddress,
  CompanyCreateBankAccount,
  CompanyFile,
  CompanyInfo,
  CompanyListItem,
  CompanyProjectRecipientsParams,
  CompanyProjectSite,
  CompanyRecipient,
  CompanyStatus,
  CompanyType,
  Employee,
  ProjectSiteParams,
  ShortEmployee,
  SmartContractSigner,
} from "@app/models";
import instance from "./instance";

export interface CompanyCreateUpdateDTO {
  id?: string;
  typeId: string;
  name: string;
  bin: string;
  ndsId: string;
}

interface UploadFileResponse {
  data: Array<{
    id: string;
    name: string;
    path: string;
    isDeleted: boolean;
  }>;
}

/**
 * @deprecated В функции используется неверная модель данных.
 * Для получения списка компаний необходимо использовать `getCompanyList`
 */
export function getCompanies(
  params: PageableParams<Company>
): Promise<PageableResponse<Company>> {
  return instance()
    .get("/company", {
      params,
    })
    .then((response) => response.data);
}

export function getCompanyList(
  params: PageableParams<CompanyListItem>
): Promise<PageableResponse<CompanyListItem>> {
  return instance()
    .get("/company", {
      params,
    })
    .then((response) => response.data);
}

export function getCompaniesFiltered(
  params: PageableParams<Company>
): Promise<PageableResponse<Company>> {
  const _params = {
    ...params,
    Statuses: [
      CompanyStatus.AwaitingConfirmation,
      CompanyStatus.Confirmed,
      CompanyStatus.Created,
    ],
  };
  return instance()
    .get("/company", {
      params: _params,
    })
    .then((response) => response.data);
}

export function getOrganizationCompanies(params: any): Promise<any> {
  return instance()
    .get("/company", {
      params,
    })
    .then((response) => response.data);
}

export function getMyCompanies(
  params?: PageableParams<Company>
): Promise<PageableResponse<Company>> {
  return instance()
    .get("/company/by-user", { params })
    .then((response) => response.data);
}

export function createCompany(
  data: CompanyCreateUpdateDTO
): Promise<APIResponse<Company>> {
  const formData = new FormData();

  Object.keys(data).forEach((fieldName) => {
    formData.append(
      fieldName,
      data[fieldName as keyof CompanyCreateUpdateDTO] as string
    );
  });

  return instance()
    .post("/company", formData)
    .then((response) => response.data);
}

export function onCreateCompany(data: any): Promise<any> {
  return instance()
    .post("/company", data)
    .then((response) => response.data);
}

export function onUpdateCompany(data: any): Promise<APIResponse<any>> {
  return instance()
    .put("/company", data)
    .then((response) => response.data);
}

export function onCreateAddress(data: any): Promise<APIResponse<any>> {
  return instance()
    .post("/company/address", data)
    .then((response) => response.data);
}

export function onUpdateAddress(
  data: any
): Promise<APIResponse<CompanyCreateAddress>> {
  return instance()
    .put("/company/address", data)
    .then((response) => response.data);
}

export function onAddBankAccount(
  data: any
): Promise<APIResponse<CompanyCreateBankAccount>> {
  return instance()
    .post("/company/bankAccount", data)
    .then((response) => response.data);
}

export function onUpdateBankAccount(
  data: any
): Promise<APIResponse<CompanyCreateBankAccount>> {
  return instance()
    .put("/company/bankAccount", data)
    .then((response) => response.data);
}

export function deleteBankAccount(data: any): Promise<any> {
  return instance()
    .delete("/company/bankAccount", {
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    })
    .then((response) => response.data);
}

export function onAddCompanyFile(data: any): Promise<APIResponse<any>> {
  const formData = new FormData();
  formData.append("CompanyId", data.CompanyId);
  formData.append("File", data.File);
  formData.append("TypeId", data.TypeId);

  return instance()
    .post("/company/file", formData)
    .then((response) => response.data);
}

export function deleteFile(data: any): Promise<APIResponse<any>> {
  return instance()
    .delete(`/company/file`, {
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    })
    .then((response) => response.data);
}

export function confirmCompanyStatus(
  data: CompanyConfirmStatus
): Promise<APIResponse<any>> {
  return instance()
    .put(`/company/status`, data)
    .then((response) => response.data);
}

export function updateCompany(
  data: CompanyCreateUpdateDTO
): Promise<APIResponse<Company>> {
  const formData = new FormData();

  Object.keys(data).forEach((fieldName) => {
    formData.append(
      fieldName,
      data[fieldName as keyof CompanyCreateUpdateDTO] as string
    );
  });

  return instance()
    .put("/company", formData)
    .then((response) => response.data);
}

export function getCompanyEmployees(
  companyId: string
): Promise<APIResponse<Employee[]>> {
  return instance()
    .get(`/company/${companyId}/employees`)
    .then((response) => response.data);
}

export function deleteCompanyEmployee(
  companyId: string,
  userId: string
): Promise<APIResponse<any[]>> {
  return instance()
    .delete(`/company/employee`, {
      data: {
        companyId: companyId,
        userId: userId,
      },
    })
    .then((response) => response.data);
}

export function getCompanyAddresses(
  companyId: string
): Promise<APIResponse<CompanyAddress[]>> {
  return instance()
    .get(`/company/${companyId}/addresses`)
    .then((response) => response.data);
}

export function getCompanyTypes(): Promise<PageableResponse<CompanyType>> {
  return instance()
    .get(`/companyTypes`)
    .then((response) => response.data);
}

export function getContractBasisType(): Promise<any> {
  return instance()
    .get(`/contractBasisType`)
    .then((response) => response.data);
}

export function getCompanyFileTypes(): Promise<any> {
  return instance()
    .get(`/CompanyFileType/short`)
    .then((response) => response.data);
}

export function getBanks(
  params: PageableParams<any>
): Promise<PageableResponse<any>> {
  return instance()
    .get("/bank", {
      params,
    })
    .then((response) => response.data);
}

// export function joinAnCompany(companyId: string): Promise<APIResponse> {
//   return instance()
//     .post(`/company/${companyId}/add-request`)
//     .then((response) => response.data);
// }

export function joinAnCompany(data: any): Promise<APIResponse> {
  return instance()
    .post(`/company/user/request`, data)
    .then((response) => response.data);
}

export function confirmCompanyJoinRequest(data: {
  companyId: string;
  userId: string;
  accessLevel: number;
}): Promise<APIResponse> {
  return instance()
    .put(`/company/user/request/confirm`, data)
    .then((response) => response.data);
}

export function getCompanyJoinRequests(
  companyId: string
): Promise<PageableResponse<Employee>> {
  return instance()
    .get(`/company/${companyId}/user/requests`)
    .then((response) => response.data);
}

export function getCompanyEmployeesShort(
  companyIds: string[]
): Promise<PageableResponse<ShortEmployee>> {
  return instance()
    .get("/company/short-employees", {
      params: { companyIds },
    })
    .then((response) => response.data);
}

export function getCompanySigners(
  id: string
): Promise<APIResponse<SmartContractSigner[]>> {
  return instance()
    .get(`/company/${id}/signers`)
    .then((response) => response.data);
}

export function getCompanyBankAccounts(
  id: string
): Promise<APIResponse<BankAccount[]>> {
  return instance()
    .get(`/company/${id}/bankAccounts`)
    .then((response) => response.data);
}

export function getCompanyDocuments(
  id: string
): Promise<APIResponse<CompanyFile[]>> {
  return instance()
    .get(`/company/${id}/files`)
    .then((response) => response.data);
}

export function getCompanyProjectSites(
  params: PageableParams<CompanyProjectSite> & ProjectSiteParams
): Promise<PageableResponse<CompanyProjectSite | null>> {
  return instance()
    .get(`/company-project-sites`, { params })
    .then((response) => response.data);
}

export function getCompanyById(id: string): Promise<APIResponse<CompanyInfo>> {
  return instance()
    .get(`/company/${id}`)
    .then((response) => response.data);
}

export function getCompanyProjectRecipients(
  params: CompanyProjectRecipientsParams
): Promise<APIResponse<CompanyRecipient[]>> {
  return instance().get(`/companyProjectRecipients`, { params });
  // .then((response) => response.data);
}
